import './App.css';
import { useState } from 'react';


function App() {

  const [selected, setSelected] = useState("0");
  const [data, setData] = useState([
    {name: '', phone: '', btntitle:'Call +1 (844) 941-44-64'},
    {name: 'Delta Airline', phone: 'tel:18446261078', btntitle:'Call +1 (844) 626-1078'},
    {name: 'British Airline', phone: 'tel:18449845193', btntitle:'Call +1 (844) 984-5193'},
    {name: 'American Airline', phone: 'tel:18337740854', btntitle:'Call +1 (833) 774-0854'},
    {name: 'Southwest Airline', phone: 'tel:18449248698', btntitle:'Call +1 (844) 924-8698'},
    {name: 'United Airline', phone: 'tel:18449414464', btntitle:'Call +1 (844) 941-4464'},
    {name: 'Alaska Airline', phone: 'tel:18444180946', btntitle:'Call +1 (844) 418-0946'},
  ]);

  return (
    <>
    <div class="hero">
    <div class="container hero__container">
      <div class="hero__header">
        <div class="header">
          <div class="header__logo"><a class="link" href="#"><span class="domain"></span></a></div>
          <div class="header__menu">
            <div class="header-menu">
              <div class="header-menu__item"><a class="link" href="/index.html">Home</a></div>
              <div class="header-menu__item"><a class="link" href="#contactnum">Contact Us</a></div>
              <div class="header-menu__item"><a class="link" href="policy.html">Privacy Policy</a></div>
              <div class="header-menu__item"><a class="link" href="terms.html">Terms and Conditions</a></div>
            </div>
          </div>
          
          <div class="header__pull">
            <button class="burger" type="button" data-action="show-menu"><span class="burger__bar"></span><span
                class="burger__bar"></span><span class="burger__bar"></span></button>
          </div>
        </div>
        <div class="hero__intro">
          <div class="intro">
            <div class="intro__heading">Airlines Reservation Desk</div>
            <div class="intro__list">
              <div class="intro-list">
                <div class="intro-list__item">
                  <div class="intro-list-item">New bookings</div>
                </div>
                <div class="intro-list__item">
                  <div class="intro-list-item">Changes</div>
                </div>
                <div class="intro-list__item">
                  <div class="intro-list-item">Cancellations</div>
                </div>
                <div class="intro-list__item">
                  <div class="intro-list-item">Customer Service</div>
                </div>
                <div class="intro-list__item">
                  <div class="intro-list-item">Refunds</div>
                </div>
              </div>
            </div>


            <select onChange={(e) => {setSelected(e.target.value)}} className='button white'>
            <option value="0">---- Select Airline ----</option>
                  <option value="1">Delta Airline</option>
                  <option value="2">British Airline</option>
                  <option value="3">American Airline</option>
                  <option value="4">Southwest Airline</option>
                  <option value="5">United Airline</option>
                  <option value="6">Alaska Airline</option>
                </select>
                
                <div><br/></div>

            <div class="intro__button">
              {selected === '0' ? <div class="intro-button hide">
                <div class="intro-button__button"><a class="button bold" href={data[Number(selected)].phone}>{data[Number(selected)].btntitle}</a></div>
                <div class="intro-button__description">{data[Number(selected)].name + " - 24/7 Helpline"}</div>
              </div> : <div class="intro-button">
                <div class="intro-button__button"><a class="button bold" href={data[Number(selected)].phone}>{data[Number(selected)].btntitle}</a></div>
                <div class="intro-button__description">{data[Number(selected)].name + " - 24/7 Live Helpline"}</div>
              </div>}
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="advantages">
    <div class="container advantages__container">
      <div class="advantages__items">
        <div class="advantages__item">
          <div class="advantages-item">
            <div class="advantages-item__icon"><img class="image advantages-item__image"
                src="./assets/images/advantage-price-guarantee.svg" alt="Best Price Guarantee"/></div>
            <div class="advantages-item__info">
              <div class="advantages-item__heading">Best Price Guarantee</div>
              <div class="advantages-item__description">Cheap flight tickets for all destinations</div>
            </div>
          </div>
        </div>
        <div class="advantages__item">
          <div class="advantages-item">
            <div class="advantages-item__icon"><img class="image advantages-item__image"
                src="./assets/images/advantage-customer-service.svg" alt="Excellent Customer service"/></div>
            <div class="advantages-item__info">
              <div class="advantages-item__heading">Excellent Customer service</div>
              <div class="advantages-item__description">Quick changes and cancellations</div>
            </div>
          </div>
        </div>
        <div class="advantages__item">
          <div class="advantages-item">
            <div class="advantages-item__icon"><img class="image advantages-item__image"
                src="./assets/images/advantage-secure-booking.svg" alt="Secure Booking"/></div>
            <div class="advantages-item__info">
              <div class="advantages-item__heading">Secure Booking</div>
              <div class="advantages-item__description">Your Personal Information is secure</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contacts" id="contactnum">
    <div class="container contacts__container">
      <div class="contacts__heading">Contact us</div>
      <div class="contacts__phone"><a class="contacts-phone" href="tel:contact@qpdip.com">contact@qpdip.com</a></div>
      </div>
  </div>
  <div class="footer">
    <div class="container footer__container">
      <div class="footer__copyrights">Copyright © 2022 <span class="domain"></span></div>
      <div class="footer__menu">
        <div class="footer-menu">
        <div class="footer-menu__item"><a class="link" href="./about.html">About Us</a>
          </div>
          <div class="footer-menu__item"><a class="link" href="./policy.html">Privacy Policy</a>
          </div>
          <div class="footer-menu__item"><a class="link" href="./terms.html">Terms and Conditions</a>
          </div>
        </div>
      </div>
    </div>
  </div>


    </>
  );
}

export default App;
